import './App.css';
import Grid from "@cloudscape-design/components/grid";

import './sass/Color.sass';
import './sass/Text.sass';
import './sass/Struct.sass';
import './sass/Images.sass';

import Formulario from './Formulario';
import * as React from "react";
import { useNavigate } from "react-router-dom";



const TwoColImgLeft = ({ children, imgId, title, imgWidth }) => {
    let imgw = imgWidth ? imgWidth : 4
    let gridDef = [{ colspan: { xs: imgw, default: 12 } }, { colspan: { xs: 12 - imgw, default: 12 } }]
    if (title) gridDef.unshift({ colspan: 12 })
    return (
        <Grid gridDefinition={gridDef} >
            {title ? <h2>{title}</h2> : null}
            <div id={imgId}>
            </div>
            {children}
        </Grid>)
}


const TwoColImgRight = ({ children, imgId, title, imgWidth }) => {
    let imgw = imgWidth ? imgWidth : 4
    let gridDef = [{ colspan: { xs: 12 - imgw, default: 12 } }, { colspan: { xs: imgw, default: 12 } }]
    if (title) gridDef.unshift({ colspan: 12 })
    return (
        <Grid gridDefinition={gridDef}>
            {title ? <h2>{title}</h2> : null}
            {children}
            <div id={imgId}>
            </div>
        </Grid>)
}

const TwoCol = ({ children, imgWidth }) => {
    let imgw = imgWidth ? imgWidth : 4
    let gridDef = [{ colspan: { xs: imgw, default: 12 } }, { colspan: { xs: 12 - imgw, default: 12 } }]
    return (
        <Grid gridDefinition={gridDef}  >
            {children}
        </Grid>)
}



const HomeBox = ({ children }) =>
    <div className='Home-Box-1'>
        <Grid gridDefinition={[

            { colspan: { s: 5, default: 11, l: 4 }, offset: { default: 1, l: 2 } }, { colspan: { s: 5, default: 12, l: 4 } }]}>
            {children}
        </Grid>
    </div>

const HomeBoxAlt = ({ children }) =>
    <div className='Home-Box-2'>
        <Grid gridDefinition={[
            { colspan: { s: 6, default: 11, l: 4 }, offset: { default: 1, l: 2 } },
            { colspan: { s: 4, default: 12, l: 4 } }
        ]}>
            {children}
        </Grid>
    </div>

const HomeBoxAltInverted = ({ children }) =>
    <div className='Home-Box-2'>
        <Grid gridDefinition={[
            { colspan: { s: 5, default: 11, l: 4 }, offset: { default: 1, l: 2 } },
            { colspan: { s: 5, default: 11, l: 4 } }
        ]}>
            {children}
        </Grid>
    </div>


const HomeBoxAltFull = ({ children, className }) =>
    <div className={className}>
        <Grid gridDefinition={[
            { colspan: { s: 10, l: 8, default: 11 }, offset: { default: 1, l: 2 } }

        ]}>
            {children}
        </Grid>
    </div>

const HomeBoxCol = ({ children }) =>
    <div className='home-box-text'>
        <Grid gridDefinition={children.map(c => { return { colspan: 12 } })}>
            {children}
        </Grid>
    </div>





const Home = ({ content }) => {
    let navigate = useNavigate()

    return (
        <Grid disableGutters gridDefinition={[{ colspan: 12 }, { colspan: 12 }, { colspan: 12 }, { colspan: 12 }]}>

            <HomeBox>
                <HomeBoxCol>
                    <div className='Home-text white' id='home_text_1' >
                        Experiencias de contacto naturales, personalizadas y efectivas
                    </div>
                    <div className='Home-title' id='home_title_1'  >
                        Conect<span className="Highlight">IA</span> Cloud
                    </div>
                    <div className='Home-text white' id='home_text_1'>
                        <p>Adoptando las tecnologías de <span className="Highlight">nube</span> e <span className="Highlight">Inteligencia Artificial</span>,
                            puede transformar su centro de contacto para entregar una atención verdaderamente única.
                        </p>
                    </div>
                    <button onClick={() => { navigate("/demo") }}>Experimente la demo</button>
                </HomeBoxCol>
                <div className='first-image'>
                    <p></p>
                </div>

            </HomeBox>

            <HomeBoxAlt>

                <HomeBoxCol>

                    <div className='Home-title' id='home_title_2'>
                        El Centro de Contacto <span className='Highlight'>reimaginado</span> con IA
                    </div>
                    <div className='Home-text' id='home_text_2'>
                        La experiencia el Centro de Contacto es uno de los momentos de la verdad: hoy los clientes esperan que
                        la empresa los conozca y entienda. Esperan y que el contacto sea accesible, sin fricciones, rápido y efectivo. <br /><br />

                        Permita que sus clientes experimenten este nuevo estándar con <span className='Highlight-alt'><em>ConectIA Cloud</em></span>.
                        Aproveche nativamente el <strong><em>Reconocimiento del Habla, Modelos de Lenguaje  </em></strong>
                        para entender lo que dicen sus clientes durante el contacto. <br /><br />

                        Sume la <strong><em>automatización, eventos de tiempo real y Síntesis de Voz</em></strong> para lograr un contacto personal, dinámico y efectivo.

                    </div>

                    {/*                     <div className='Home-text' id='home_text_3'>
                        <p>Utilice <strong>Automatización</strong> Mejore sus capacidades y las de sus agentes con herramientas para resolver contactos de manera eficiente,
                            en cualquier momento y en cualquier lugar.</p>
                    </div> */}

                    {/*                     <div className='Home-text' id='home_text_2'>
                        Incorpore la verdadera <strong>omnicanalidad</strong> para entregar una experiencia
                        consistente en todos los canales.
                    </div> */}
                </HomeBoxCol>
                <div className='second-image'>
                    <div className='second-image-overlay'>
                        <p></p>
                    </div>
                </div>

            </HomeBoxAlt>

            <HomeBoxAltFull className='Home-Box-3'>

                <HomeBoxCol>
                    <div className='Home-title right' id='home_title_1'  >
                        Verdadera <span className="Highlight">Omnicanalidad</span>, en una ventana
                    </div>
                    <TwoColImgLeft imgId={'omnichannel'} imgWidth={7} >

                        <div className='Home-text' id='home_text_2'>
                            Incorpore la capacidad de recibir en la misma interfaz del agente una <strong><em>llamada, web chat</em></strong>, mensajes privados de <strong><em>Facebook, Instagram y Twitter</em></strong>,
                            así como también desde <strong><em>SMS, WhatsApp y Apple Business Chat. </em></strong><br /><br />
                            Sus clientes podrán contactarlo de manera más fácil y conveniente desde la plataforma que prefieren,
                            con una experiencia coherente.<br /><br />
                        </div>
                    </TwoColImgLeft>

                </HomeBoxCol>

            </HomeBoxAltFull >

            <HomeBoxAltFull className='Home-Box-3 white'>
                <HomeBoxCol>
                    <div className='Home-title' id='home_title_2'>
                        Su Centro de Contacto <span className='Highlight'>Las 24 horas, 365 días</span>
                    </div>
                    <br />
                    <TwoColImgLeft imgId={'bots'} title={'Bot de Autoservicio Omnicanal'}>
                        <div className='Home-text-right' id='home_text_2'>
                            Usando <strong><em>VoiceBots y ChatBots</em></strong>, ofrezca a sus clientes atención rápida en cualquier momento
                            para consultar el estado de sus pedidos o el seguimiento de sus tickets.
                            Incluso pueden realizar acciones como hacer un pedido o programar una cita. Si el bot no lo resuelve, puede escalarlo a un agente de forma transparente<br /><br />
                            Estas herramientas de <strong><em>autoservicio</em></strong> permiten que sus agentes se centren en resolver
                            los casos más difíciles. Sus clientes agradecerán estar <strong><em>menos tiempo en la cola.</em></strong>
                        </div>

                    </TwoColImgLeft>
                </HomeBoxCol>
            </HomeBoxAltFull>


            <HomeBoxAltFull className='Home-Box-3 '>
                <HomeBoxCol>
                    <div className='Home-title right' id='home_title_2'>
                        Convierta a sus agentes en  <span className='Highlight'>súper agentes</span>
                    </div>
                    <br />
                    <TwoColImgLeft imgId={'profiles'} title={'Perfil de Cliente automático'}>
                        <div className='Home-text-right' id='home_text_2'>
                            Conozca a su cliente antes de responder una llamada o chat.
                            Utilice el <strong><em>perfil unificado de cliente</em></strong> para mostrar al agente la información completa del contacto entrante y así acelerar la atención. <br /><br />

                            Además puede utilizar la <strong><em>automatización y eventos de tiempo real</em></strong> para
                            obtener datos de contexto del cliente desde sus propios sistemas de información tales como: tickets abiertos, pedidos en curso y recomendaciónes de nuevos productos y servicios.

                        </div>

                    </TwoColImgLeft>
                    <br /><br />

                    <TwoColImgRight
                        imgId={'voiceid'}
                        title={'Identificación mediante el análisis de la Voz'}
                        imgWidth={5}>
                        <div className='Home-text-left' id='home_text_2'>
                            Reduzca el tiempo en identificar a la persona que llama y el riesgo de fraudes utilizando <strong><em>Machine Learning</em></strong><br /><br />
                            Si está activado, <span className='Highlight'>ConectIA</span> automáticamente analiza los atributos del habla como el ritmo y el tono para crear una huella de voz digital,
                            optimizando la experiencia de autenticación para los clientes en su próxima llamada.
                        </div>
                    </TwoColImgRight>
                    <br /><br />
                    <TwoColImgLeft
                        imgId={'wisdom'}
                        title={'Conocimiento Automático'}
                        imgWidth={5}>
                        <div className='Home-text-right' id='home_text_2'>


                            El tiempo es valioso para su cliente y para su agente. Para resolver efectiva y rápidamente disponga automáticamente
                            la <strong><em>base de conocimiento</em></strong> para el agente en la misma pantalla y mostrar<strong><em> guías de paso a paso</em></strong> para
                            resolver los problemas más rápido.<br /><br />

                            También puede activar el <strong><em>reconocimiento y procesamiento automático de la conversación </em></strong> para
                            que los agentes reciban automáticamente los resultados de la base de conocimiento y la guía de acción.
                        </div>
                    </TwoColImgLeft>
                </HomeBoxCol>
            </HomeBoxAltFull>

            <HomeBoxAltFull className='Home-Box-3 different'>

                <HomeBoxCol>
                    <div className='Home-title right' id='home_title_1'  >
                        <br />
                        <span className="Highlight">Campañas</span> de contactos salientes
                        <br />
                    </div>
                    <TwoColImgLeft imgId={'campanas'} imgWidth={5} >

                        <div className='Home-text' id='home_text_2'>

                            Conecte proactivamente con sus clientes de manera personalizada y en diferentes canales y países.
                            Automatice las comunicaciones en tiempo real, como actualizaciones o recordatorios,
                            y planifique mensajes personalizados para grupos de clientes.
                            <br /><br />

                            También puede utilizar un <strong><em>Bot de diálogo</em></strong> para una campaña interactiva
                            sin agentes y obtener informes sobre el desempeño de su campaña, incluyendo tasas de llegada, apertura y clicks de correos, tiempo de
                            respuesta del cliente y tiempo de atención para llamadas con agentes.


                        </div>
                    </TwoColImgLeft>

                </HomeBoxCol>

            </HomeBoxAltFull >


            <HomeBoxAltFull className='Home-Box-3 white'>
                <HomeBoxCol>
                    <div className='Home-title' id='home_title_2'>
                        Evalúe <span className='Highlight'>ConectIA</span> como su centro de contacto
                    </div>
                    <br />
                    <TwoCol imgWidth={6} >
                        <div className='form-text'>
                            <div className='Home-text-right' id='home_text_2'>
                                Comuníquese con nosotros para profundizar en las diferentes herramientas y cómo pueden ayudar a mejorar la experiencia del contacto con su empresa.
                                <br/> <br/>
                            
                                Tiene una idea en mente? y no está seguro de los resultados. Permítanos apoyar con estudios y referencias.

                            </div>
                            <br /><br />
                            
                        </div>
                        <Formulario content={content} />
                    </TwoCol>
                </HomeBoxCol>
            </HomeBoxAltFull>




        </Grid >

    )
}


export default Home