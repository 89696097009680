import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import HomeApp from './HomeApp';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import "@cloudscape-design/global-styles/index.css"
import { applyMode, Mode } from '@cloudscape-design/global-styles';
import awsExports from './aws-exports';

import { Amplify, Auth } from 'aws-amplify';

Amplify.configure(awsExports);
Auth.currentCredentials()
  .then(d => d)
  .catch(e => console.log('error: ', e))

applyMode(Mode.Dark)



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <BrowserRouter>
      <HomeApp/>
    </BrowserRouter>


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
