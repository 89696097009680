import * as React from "react"
import { useState, useEffect } from "react"

const waitForVariable = (variableName, timeout = 5000) => {
    return new Promise((resolve, reject) => {
        const intervalId = setInterval(() => {
            if (window[variableName] !== undefined) { // Check if the variable is defined
                clearInterval(intervalId) // Stop the interval
                resolve(window[variableName]) // Resolve the promise with the variable value
            }
        }, 100) // Check every 100ms

        setTimeout(() => {
            clearInterval(intervalId) // Stop the interval
            reject(new Error(`Timeout: ${variableName} not initialized`)) // Reject the promise with an error if timeout occurs
        }, timeout) // Set timeout for the waiting period
    })
}




const Demo = () => {

    const loadChat = (x, id) => {
        const w = window
        w[x] =  w[x] || function() { (w[x].ac = w[x].ac || []).push(arguments) }

        waitForVariable('amazon_connect')
            .then((amazon_connect) => {
                console.log('Amazon connect existe')
                amazon_connect('styles', { openChat: { color: '#ffffff', backgroundColor: '#ff4713' }, closeChat: { color: '#ffffff', backgroundColor: '#ff4713' } })
                amazon_connect('snippetId', 'QVFJREFIaUczMG9CY2pZeGVON3dIRFJwVkJlRHFKcW1kTTRlazcxajNHMVd5VzM1ZWdHRjNuODUraE9yNTljYy9HNjNRU3ZTQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNcUU1MW1IS1VSTzN5YzRqR0FnRVFnQ3RBRWRzQkdraE1wYkI1Z0RvS3lUQlBReHNJZTFwai9EZWljanRweDBnL0NOeHNyaGFSTXdNNXR0bWw6Ok90VHJEQ2c3bWMyV01OZi93NmpLMm9jbVRRUGh4UVRpbUY2S1Bacm8velFCZEhWTS9VTVN2VG9qV0hYSGE1K2xoMEFvWnFkSzlGb0lhVTZ4cVFQVUtvY3c3Wk1RSkRIOUdmRzY2dEVwRDFIVlJKb21RdHVucEdTd0g0dFNTVkc3NDI2UERuT1JwOGRkZVJxVWdKenV1Q0dJSlRmM3lZaz0=')
                amazon_connect('supportedMessagingContentTypes', ['text/plain', 'text/markdown'])
                amazon_connect('customerDisplayName', function(callback) {
                    const displayName = 'Cliente';
                    callback(displayName);
                  });
                console.log('LOAD CHAT')
        
                const head = document.querySelector("head")
                const script = document.createElement("script")
                script.src = 'https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js'
                script.id = id
                head.appendChild(script)
            })
            .catch((error) => {
                // Timeout occurred or variable not initialized
                console.error(error)
            })
        
    }



    useEffect(() => {
        
        loadChat('amazon_connect','e9efd50c-abaf-4f00-b5d2-ab186f7a8027')

       
        return () => {
            const script = document.getElementById('e9efd50c-abaf-4f00-b5d2-ab186f7a8027')
            document.getElementById('amazon-connect-chat-widget').remove()
            const head = document.querySelector("head")
            console.log('removing')
            head.removeChild(script);
          }
    }, [])

    return (<div id='demo'><p>Demo</p></div>)

}





export default Demo

