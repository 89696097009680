import * as React from "react";
import Form from "@cloudscape-design/components/form";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Header from "@cloudscape-design/components/header"
import FormField from "@cloudscape-design/components/form-field";
import Input from "@cloudscape-design/components/input";
import Textarea from "@cloudscape-design/components/textarea";
import Container from "@cloudscape-design/components/container";
import Grid from "@cloudscape-design/components/grid";

import Box from "@cloudscape-design/components/box";
import { Modal } from "@cloudscape-design/components";


import { API, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';


import * as mutations from './graphql/mutations';

Amplify.configure(awsExports);


const Formulario = ({ content }) => {
    const [nombre, setNombre] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [company, setCompany] = React.useState("");
    const [comentario, setComentario] = React.useState("");
    const [submitMsg, setSubmitMsg] = React.useState("Hola");
    const [visible, setVisible] = React.useState(false);
    const [labels, setLabels] = React.useState({
        title: "Indique sus datos",
        name: "Nombre",
        name_placeholder: "Tu nombre",
        email: "email",
        phone: "Celular",
        company: "Empresa",
        comment: "como podemos ayudar?",
        button_send: "Enviar"
    });
    const submitFormulario = async (e) => {
        e.preventDefault()

        const contactoDetails = {
            name: nombre,
            email: email,
            phone: phone,
            company: company,
            comment: comentario
        }
        console.log(contactoDetails)
        const newComment = await API.graphql({ query: mutations.createContact, variables: { input: contactoDetails }, authMode: GRAPHQL_AUTH_MODE.AWS_IAM });
        console.info(newComment)
        const comment = newComment.data.createContact
        if ('id' in comment) {
            setSubmitMsg("Gracias por tomarse el tiempo " + comment.name + ",  lo contactaremos a la brevedad.")
            setVisible(true)
        }
    }
    const clearForm = () => {
        setNombre("")
        setEmail("")
        setPhone("")
        setComentario("")
        setCompany("")
    }

    const dismissModal = () => {
        setVisible(false)
        clearForm()
    }

    React.useEffect(() => {
        if ("form_field_labels" in content) {
            //console.log(content.form_field_labels)
            setLabels(JSON.parse(content.form_field_labels))
        }

    }, [content])

    return (
        <Grid gridDefinition={[{ colspan: { xs: 6,  s: 6, ms: 4, default: 12 }, offset:{ xs: 1,  s: 2, ms: 2, default: 0 } }]}>
            <Container className="container-form">
                <Modal
                    onDismiss={dismissModal}
                    visible={visible}
                    closeAriaLabel="Close modal"
                    footer={
                        <Box float="right"  >
                            <SpaceBetween direction="horizontal" size="s">
                                <Button variant="primary" onClick={dismissModal}>Ok</Button>
                            </SpaceBetween>
                        </Box>
                    }
                    header="Got it, Thanks!"
                >
                    {submitMsg}
                </Modal>
                <form className='formulario' onSubmit={submitFormulario}>
                    <Form
                        actions={

                                <Button variant="primary" >{labels.button_send}</Button>

                        }
                        header={<Header variant="h1">{labels.title}</Header>}

                    >

                        <FormField label={labels.name} stretch={true}>
                            <Input value={nombre} placeholder={labels.name_placeholder} onChange={event => setNombre(event.detail.value)} />
                        </FormField>

                        <FormField label={labels.email} stretch={true}>
                            <Input type="email" placeholder="email@company.com" value={email} onChange={event => setEmail(event.detail.value)} />
                        </FormField>

                        <FormField stretch={true} label={labels.phone}>
                            <Input type="phone" inputMode='tel' value={phone} onChange={event => setPhone(event.detail.value)} />
                        </FormField>

                        <FormField label={labels.company}>
                            <Input type="text" value={company} onChange={event => setCompany(event.detail.value)} />
                        </FormField>

                        <FormField label={labels.comment}>
                            <Textarea
                                onChange={event => setComentario(event.detail.value)}
                                value={comentario}
                            />

                        </FormField>
                    </Form>
                </form>

            </Container>
        </Grid>
    )
}


export default Formulario