import * as React from "react";
import TopNavigation from "@cloudscape-design/components/top-navigation";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import './sass/Menu.sass';

const Menu = ({ content }) => {
  let navigate = useNavigate()
  const [labels, setLabels] = useState({
    about: "Demo",
    solutions: "Solutions",
    omnichannelcloudcontactcenter: "Omnichannel Cloud Contact Center",
    selfservicebots: "Voice and Chat Bots",
    callanalytics: "Call Insights Analytics",
    demos: "Demos",
    selfservicechatdemo: "Self service chat Demo",
    requestdemo: "Request a Demo",
    explorenewideas: "Explore New Ideas",
    contactus: "Contact Us"


  })

  useEffect(() => {
    if ("menu_labels" in content) {
      setLabels(JSON.parse(content.menu_labels))
    }

  }, [content])
  return (
    <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
      <TopNavigation 
        identity={{
          onFollow: (() => { navigate("/") }),
          title: <div className='title'>Conect <span className='highlight'><strong><em> IA</em></strong></span></div>,

        }}
        utilities={[
          {
            type: "button",
            text: <button className="orange-button">{labels.about}</button>,
            onClick: (() => { navigate("/demo") })
          },
          {
            type: "menu-dropdown",
            text: labels.solutions,
            items: [

              {
                id: "solutions-group",
                text: labels.solutions,
                items: [
                  { id: "omnichannel-cloud-contact-center", text: labels.omnichannelcloudcontactcenter },
                  { id: "self-service-bots", text: labels.selfservicebots },
                  { id: "call-analytics", text: labels.callanalytics }
                ]
              },
              {
                id: "demo-group",
                text: labels.demos,
                items: [
                  { id: "chat-demo", text: labels.selfservicechatdemo },
                  { id: "custom-demo", text: labels.requestdemo },
                ]
              }
            ]

          },
          {
            type: "button",
            text: labels.explorenewideas,

            //onClick: (() => {navigate("/ideas")})

          },
          {
            type: "button",
            text: labels.contactus,

            onClick: (() => {
              document.querySelector('#contact-form').scrollIntoView({
                behavior: 'smooth'
              });
            })

          },
          /*           {
                      type: "menu-dropdown",
                      text: "Customer Area",
                      iconName: "user-profile",
                      items: [
          
                        {
                          id: "support-group",
                          text: "Support",
                          items: [
                            {
                              id: "documentation",
                              text: "Documentation",
                              href: "#",
                              external: true,
                              externalIconAriaLabel:
                                " (opens in new tab)"
                            },
                            { id: "support", text: "Support" },
                            {
                              id: "feedback",
                              text: "Feedback",
                              href: "#",
                              external: true,
                              externalIconAriaLabel:
                                " (opens in new tab)"
                            }
                          ]
                        }
                      ]
                    } */
        ]}
        i18nStrings={{
          searchIconAriaLabel: "Search",
          searchDismissIconAriaLabel: "Close search",
          overflowMenuTriggerText: "More",
          overflowMenuTitleText: "All",
          overflowMenuBackIconAriaLabel: "Back",
          overflowMenuDismissIconAriaLabel: "Close menu"
        }}
      />
    </div>
  );
}


export default Menu