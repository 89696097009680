import * as React from "react";

import "@cloudscape-design/global-styles/index.css"
import './App.css';
import AppMenu from './Menu'
import Home from './Home'
import AboutUs from './AboutUs';
import AppLayout from '@cloudscape-design/components/app-layout';

import { Auth } from "aws-amplify";

import { API, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import * as queries from './graphql/queries'

import { useState, useEffect } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import Demo from "./demo";




const HomeApp = () => {
  const [content, setContent] = useState([])
  const [style, setStyle] = useState("main-no-blur")
  const [localContent, setLocalContent] = useState({})
  const [languageCode, setLanguageCode] = useState('es_US')
  const [fetching, setFetching] = useState(false)


  const getContent = async () => {
    const resoponseData = await API.graphql({ query: queries.listContents, authMode: GRAPHQL_AUTH_MODE.AWS_IAM });
    setContent(resoponseData.data.listContents.items)
  }


  useEffect(() => {

    if (fetching) return
    setLanguageCode('es_US')
    //getContent()
    setFetching(true)  
  }, [fetching]);

  useEffect(() => {
    if (content.length !== 0) {
      let filteredContent = content.filter((item) => item.language_code === languageCode)
      let filterObject = Object.assign({}, ...filteredContent.map((x) => ({ [x.field_id]: x.content })))
      setLocalContent(filterObject)
      setStyle("main-no-blur")
    }
  }, [languageCode, content])


  useEffect(() => {
    if (Object.keys(localContent).length === 0) return
    //console.log(localContent)
    Object.keys(localContent).forEach(k => {
      let elem = document.getElementById(k)
      if (elem) elem.innerHTML = localContent[k]
    })
  }, [localContent]);



  return (
    <Routes >
      <Route path="/" element={<Layout content={localContent} customStyle={style} />}>
        <Route index element={<Home content={localContent} />} />
        <Route path="about" element={<AboutUs />} />
        <Route path="demo" element={<Demo />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  )
}





const Layout = ({ content, customStyle }) =>
  <div className={customStyle}>
    <AppMenu key={1} content={content} />
    <AppLayout key={2}
      headerSelector="#h"
      toolsHide={true}
      disableContentPaddings={true}
      navigationHide={true}
      footerSelector='#f'
      content={<Outlet />}
    />

    <div id='f' key={3} className='App-footer'>
      @2023 ConectIA Cloud  All Rights Reserved
    </div>
  </div>



const NoMatch = () => <div>Nada por Aqui!</div>


export default HomeApp